<template>
  <v-container fluid>
    <page-title title="Нейрографика. Отдельные алгоритмы">
      <template #description>
        <v-card-text
          style="color: black; white-space: pre-line"
          :class="{ 'body-2': $vuetify.breakpoint.xs }"
          class=" headline text-justify "
          ><span>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto autem eaque eos
            explicabo, ipsum minus modi sunt. Assumenda, consectetur culpa dolore, ducimus ex, id
            itaque libero magni nisi nobis obcaecati quis repudiandae vel voluptatem voluptatibus.
            A, ab adipisci, aperiam, architecto deleniti dolore ex harum ipsam mollitia natus odio
            perferendis qui.</span
          ></v-card-text
        >
      </template>
    </page-title>
    <!--    <select-author :list="filterSelect" />-->
    <list-component :list="modifiedArtTherapyList" @createPath="createPath" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SeparateVideosList',
  metaInfo: {
    title: 'Нейрографика | Отдельные алгоритмы',
    titleTemplate: null,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: `Нейрографика это эффективный инструмент для решения задач, достижения целей и исполнения желаний.`
      }
    ]
  },
  components: {
    ListComponent: () => import('@/components/shared/lists/ListComponent'),
    PageTitle: () => import('@/components/shared/PageTitle')
    // SelectAuthor: () => import('@/components/shared/SelectAuthor')
  },
  computed: {
    ...mapState({
      artTherapyList: state => state.artTherapy.artTherapyList,
      listAuthors: state => state.shared.listAuthors
    }),
    ...mapGetters(['filterArtTherapyList', 'filterAllArtTherapyList']),
    filterSelect() {
      return this.filterAllArtTherapyList.filter(item => item.artNeurographics)
    },
    modifiedArtTherapyList() {
      return this.filterArtTherapyList
        .filter(item => item.artNeurographics)
        .map(studio => {
          const dadaAuthor = this.listAuthors.find(author => author.id === studio.author)
          return {
            ...studio,
            authorName: `${dadaAuthor.firstName} ${dadaAuthor.lastName}`
          }
        })
    }
  },
  created() {
    if (!this.artTherapyList.length) this.$store.dispatch('getArtTherapy')
    if (!this.listAuthors.length) this.$store.dispatch('getListAuthors')
  },
  methods: {
    createPath(id) {
      this.$router.push(`/artTherapyDescription/${id}`)
    }
  }
}
</script>

<style scoped />
